import React, { PureComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import env from '/env';

// import-sort-ignore
import { getCurrentBreakpoint, getOnlyMobile, isGeneratorMobileView } from '/styles/mixins';
import { IntlProvider } from '/imports/core/api/useIntl';
import { ResponsiveProvider, useResponsive } from '/imports/core/api/responsiveContext';
import { withRouter } from 'next/router';
import {
  getLocaleFromPath,
  getDefaultLanguage,
  getAvailableLanguages,
  isJobTrackEnable,
  getDeviceInformation,
  RESUMEDONE_BLOGS,
  removeExperiment,
  getActiveVariantStorage,
} from '/lib/helpers';
import {
  BASIC_POSITION_COUNTRY,
  COMPLETE_POSITION_COUNTRY,
  LANGUAGES_CODES,
  supported,
} from '/imports/generator/api/constants';
import { MixpanelProvider } from '/imports/core/api/useMixpanel';
import { getCountry } from 'imports/checkout/api/utils';
import PageRender from '/components/PageRender';
import GlobalStateProvider from 'imports/core/api/global.context';
import { setCookie } from 'nookies';
// Track client-side page views with Segment - KEEP COMMENTED FOR NOW
// Router.events.on('routeChangeComplete', url => {
//   window.analytics.page(url)
// })

const isDev = env.NODE_ENV === 'development';
const isProd = env.NODE_ENV === 'production';

@withRouter
class Page extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    host: PropTypes.string,
    refHost: PropTypes.string,
    router: PropTypes.object,
  };

  state = {
    currentBreakpoint: undefined,
    screenResolution: {
      width: undefined,
      height: undefined,
    },
    google: {
      api: null,
      error: false,
    },
    deviceInfo: null,
    getOnlyMobile: null,
    isGeneratorMobileView: false,
  };

  get domain() {
    const { host } = this.props;
    const domain = typeof window !== 'undefined' && window.location.host;
    return host || domain;
  }

  get getInitialLocale() {
    const { router, host } = this.props;
    const { asPath = '' } = router || {};
    let path = asPath;
    if (asPath.includes('/builder')) {
      path = asPath.replace('/builder', '');
    }
    const pathLanguage = getLocaleFromPath(
      path,
      getDefaultLanguage(this.domain) === LANGUAGES_CODES.FRENCH,
      getDefaultLanguage(this.domain),
    );
    // make french  available when it's job track domain
    const availableLanguages = [
      ...getAvailableLanguages(host),
      ...(supported(
        ['bestonlineresume', 'resume-nation', 'elegantcv', 'modeles-cv', 'cvtoolspro', 'theresumeranger', 'resumedone'],
        host,
      ) || isJobTrackEnable()
        ? [LANGUAGES_CODES.FRENCH]
        : []),
    ];
    const defaultLanguage = availableLanguages.includes(pathLanguage) ? pathLanguage : getDefaultLanguage(this.domain);
    return router?.query?.language || defaultLanguage;
  }

  componentDidMount = () => {
    const { router } = this.props;
    if (!isProd) {
      this.customizePushtellDebuggerElement();
    }
    this.setState({
      currentBreakpoint: getCurrentBreakpoint(),
      screenResolution: {
        width: this.getScreenResolution()[0],
        height: this.getScreenResolution()[1],
      },
      deviceInfo: getDeviceInformation(),
      getOnlyMobile: getOnlyMobile(),
      isGeneratorMobileView: isGeneratorMobileView(),
    });
    if (router.pathname === '/app') {
      marvelEmitter.setActiveVariant('job-tracking', 'with_job_track');
    }

    const isMobile = ['xs', 'sm'].includes(getCurrentBreakpoint());
    const country = getCountry();
    window.addEventListener('resize', this.handleResize);
    removeExperiment('budapest_resume_experiment');
    removeExperiment('chicago_resume_experiment');
    removeExperiment('rotterdam_resume_experiment');
    removeExperiment('perth_resume_experiment');
    removeExperiment('riga_resume_experiment');
    removeExperiment('prague_resume_experiment');
    removeExperiment('rotterdamv3_experiment');
    removeExperiment('custom_fonts_v2_experiment');
    removeExperiment('resume_category_exp');
    removeExperiment('resume_option_heading_exp');
    removeExperiment('resume_linkedin_exp');
    removeExperiment('resume_linkedin_exp_v2');
    removeExperiment('resume_linkedin_search');
    removeExperiment('exp_jobtrack_exit_hack');
    removeExperiment('resume_upload_modal');
    removeExperiment('exp_gift_popup');
    removeExperiment('exp_skill_section');
    removeExperiment('exp_split_skill_upload');
    removeExperiment('exp_skill_system');
    removeExperiment('click_to_edit_exp');
    removeExperiment('click_to_edit_exp_new');
    removeExperiment('exp_resume_bullets');
    removeExperiment('click_to_edit_exp_new_v4');
    removeExperiment('exp_resume_bullets_v2');
    removeExperiment('click_to_edit_exp_new_v5');
    removeExperiment('click_to_edit_background_exp');
    removeExperiment('click_to_edit_exp_new_v6');
    removeExperiment('click_to_edit_funnel_exp');
    removeExperiment('click_to_edit_exp_new_v7');
    if (isMobile || BASIC_POSITION_COUNTRY.includes(country) || COMPLETE_POSITION_COUNTRY.includes(country))
      removeExperiment('exp_block_repositing');
    localStorage.removeItem('redneg-hack');
  };

  getScreenResolution = () => {
    const screenWidth = window.screen.width * window.devicePixelRatio;
    const screenHeight = window.screen.height * window.devicePixelRatio;

    return [screenWidth, screenHeight];
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  /**
   * Customizes the appearance of the Pushtell Debugger modal in development/staging mode.
   *
   * @function customizePushtellDebuggerElement
   * @returns {void}
   */
  customizePushtellDebuggerElement() {
    const devToolModal = document.querySelector('#pushtell-debugger');
    if (devToolModal) {
      devToolModal.style.maxHeight = '90vh';
      devToolModal.style.overflow = 'auto';
    }
  }

  handleResize = throttle(() => {
    this.setState({
      currentBreakpoint: getCurrentBreakpoint(),
      getOnlyMobile: getOnlyMobile(),
      isGeneratorMobileView: isGeneratorMobileView(),
    });
  }, 50);

  initialMobile = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth <= 449;
    }
    return false;
  };

  renderJobTrack = () => {
    const { host } = this.props;
    const isJobTrackDomain = ['cvdeboss', 'modeles-cv'];
    if (isJobTrackDomain.includes(host) || !isProd) {
      return (
        <MarvelExperiment name="job-tracking">
          <MarvelVariant name="with_job_track" weight={6}>
            <RenderBlockRepositioning {...this.props} locale={this.getInitialLocale} />
          </MarvelVariant>
          <MarvelVariant name="without_job_track" weight={4}>
            <RenderBlockRepositioning {...this.props} locale={this.getInitialLocale} />
          </MarvelVariant>
        </MarvelExperiment>
      );
    } else return <RenderBlockRepositioning {...this.props} locale={this.getInitialLocale} />;
  };

  render() {
    const { currentBreakpoint, screenResolution, deviceInfo, getOnlyMobile, isGeneratorMobileView } = this.state;
    const { router, refHost } = this.props;
    return (
      <MixpanelProvider>
        <ResponsiveProvider
          value={{
            breakpoint: currentBreakpoint,
            isMobile: ['sm', 'xs'].includes(currentBreakpoint),
            onlyMobile: getOnlyMobile,
            isGeneratorMobileView,
            isDev,
            isProd,
            isJobTrackDomain: isJobTrackEnable(),
            getDeviceInformation: () => deviceInfo,
            screenResolution: { ...screenResolution },
            deviceInfo: { ...deviceInfo },
            host: this.domain,
            resumedoneBlogs: RESUMEDONE_BLOGS,
            refHost,
          }}
        >
          <IntlProvider value={this.getInitialLocale}>
            <GlobalStateProvider>
              <PageRender host={this.domain} route={router.route}>
                {this.renderJobTrack()}
              </PageRender>
            </GlobalStateProvider>
          </IntlProvider>
        </ResponsiveProvider>
      </MixpanelProvider>
    );
  }
}

const RenderBlockRepositioning = (props) => {
  const { breakpoint } = useResponsive();
  const isMobile = ['xs', 'sm'].includes(breakpoint);
  const country = getCountry();

  if (isMobile || BASIC_POSITION_COUNTRY.includes(country) || COMPLETE_POSITION_COUNTRY.includes(country))
    return <RenderExpSwapColumns {...props} />;
  return (
    <MarvelExperiment name="exp_block_repositing">
      <MarvelVariant name="with_complete_repositioning">
        <RenderExpSwapColumns {...props} />
      </MarvelVariant>
      <MarvelVariant name="with_basic_repositioning">
        <RenderExpSwapColumns {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RenderExpSwapColumns = (props) => {
  return (
    <MarvelExperiment name="swap_rtl_columns_exp">
      <MarvelVariant name="control">
        <RenderTooltipPreviewExp {...props} />
      </MarvelVariant>
      <MarvelVariant name="with_swaped_columns">
        <RenderTooltipPreviewExp {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RenderTooltipPreviewExp = (props) => {
  return (
    <MarvelExperiment name="exp_tooltip_preview">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="with_tooltip">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
};

RenderBlockRepositioning.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Page;
